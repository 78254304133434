






























































import Vue from 'vue'
import { mapState } from 'vuex'
import { Component, Watch } from 'vue-property-decorator'

@Component({
  components: {
    DonateDialog: () => import(/* webpackChunkName: "donate" */ './components/dialogs/DonateDialog.vue'),
    NavDrawer: () => import(/* webpackChunkName: "drawer" */ './components/NavDrawer.vue'),
    SetCreatorDialog: () => import(/* webpackChunkName: "create" */ './components/dialogs/SetCreatorDialog.vue'),
    SetDecoderDialog: () => import(/* webpackChunkName: "decode" */ './components/dialogs/SetDecoderDialog.vue'),
    SetEncoderDialog: () => import(/* webpackChunkName: "encode" */ './components/dialogs/SetEncoderDialog.vue'),
    SetManagerDialog: () => import(/* webpackChunkName: "manage" */ './components/dialogs/SetManagerDialog.vue'),
    TransmuteDialog: () => import(/* webpackChunkName: "transmute" */ './components/dialogs/TransmuteDialog.vue'),
  },
  computed: mapState(['isDarkMode']),
})
export default class App extends Vue {
  public isDarkMode!: boolean
  public deleteSuccessful: boolean = false
  public garbage: string = 'all data'
  public isClearingSet: boolean = false
  public setToEdit: string = ''
  public showClearDialog: boolean = false
  public showEditConfirmDialog: boolean = false

  public created() {
    this.$bus.$on('delete-all-data', () => this.showClearDialog = true)
    this.$bus.$on('delete-custom-set', (set: string) => {
      this.garbage = set
      this.isClearingSet = true
      this.showClearDialog = true
    })
    this.$bus.$on('confirm-edit-custom-set', (set: string) => {
      this.setToEdit = set
      this.showEditConfirmDialog = true
    })
  }

  public clearHandler() {
    if (this.isClearingSet) {
      this.$store.dispatch('deleteSet', this.garbage)
        .then(() => this.deleteSuccessful = true)
        .then(() => this.resetState())
    } else {
      this.$store.dispatch('clearAllData')
        .then(() => this.deleteSuccessful = true)
        .then(() => this.resetState())
    }
  }

  public editConfirmedHandler() {
    this.$bus.$emit('edit-custom-set', this.setToEdit)
    this.setToEdit = ''
    this.showEditConfirmDialog = false
  }

  public resetState() {
    this.isClearingSet = false
    this.showClearDialog = false
    this.garbage = 'all data'
  }

  @Watch('isDarkMode', { immediate: true })
  private updateVuetifyTheme(theme: boolean) {
    const barColor = document.querySelector('meta[name=theme-color]') as HTMLMetaElement
    if (barColor !== null) {
      if (theme) {
        barColor.content = '#212121'
      } else {
        barColor.content = '#e65100'
      }
    }

    this.$vuetify.theme.dark = theme
  }
}
