/* tslint:disable:no-console */
import { Workbox } from 'workbox-window';

if (process.env.NODE_ENV === 'production') {
  if ('serviceWorker' in navigator) {
    var wb = new Workbox("".concat(process.env.BASE_URL, "service-worker.js"));
    wb.addEventListener('installed', function (event) {
      console.log('[sw] Installed service worker');
    });
    wb.addEventListener('waiting', function (event) {
      // @ts-ignore
      wb.messageSW({
        type: 'SKIP_WAITING'
      });
    });
    wb.addEventListener('controlling', function (event) {
      window.location.reload();
    });
    wb.register();
  }
}