function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

export function compareSets(oldSet, newSet) {
  // Check if length is different
  if (oldSet.length !== newSet.length) {
    return true;
  } // Length is the same! Check if subjects are different


  var _iteratorNormalCompletion = true;
  var _didIteratorError = false;
  var _iteratorError = undefined;

  try {
    var _loop = function _loop() {
      var _step$value = _step.value,
          name = _step$value.name,
          units = _step$value.units;
      var matches = newSet.filter(function (subject) {
        return subject.name === name && subject.units === units;
      });

      if (matches.length === 0) {
        // New set lacks old subject
        return {
          v: true
        };
      }
    };

    for (var _iterator = oldSet[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
      var _ret = _loop();

      if (_typeof(_ret) === "object") return _ret.v;
    } // Subject sets are assumed to be equal

  } catch (err) {
    _didIteratorError = true;
    _iteratorError = err;
  } finally {
    try {
      if (!_iteratorNormalCompletion && _iterator.return != null) {
        _iterator.return();
      }
    } finally {
      if (_didIteratorError) {
        throw _iteratorError;
      }
    }
  }

  return false;
}
var sets = [{
  name: 'PSHS Grade 7',
  subjects: [{
    name: 'Integrated Science',
    units: 1.7
  }, {
    name: 'Mathematics',
    units: 1.7
  }, {
    name: 'Computer Science',
    units: 1
  }, {
    name: 'English',
    units: 1.3
  }, {
    name: 'Filipino',
    units: 1
  }, {
    name: 'Social Science',
    units: 1
  }, {
    name: 'PEHM',
    units: 1
  }, {
    name: 'Values Education',
    units: 0.7
  }, {
    name: 'AdTech',
    units: 1
  }]
}, {
  name: 'PSHS Grade 8',
  subjects: [{
    name: 'Integrated Science',
    units: 2
  }, {
    name: 'Mathematics',
    units: 1.7
  }, {
    name: 'Computer Science',
    units: 1
  }, {
    name: 'English',
    units: 1.3
  }, {
    name: 'Filipino',
    units: 1
  }, {
    name: 'Social Science',
    units: 1
  }, {
    name: 'PEHM',
    units: 1
  }, {
    name: 'Values Education',
    units: 0.7
  }, {
    name: 'AdTech',
    units: 1
  }, {
    name: 'Earth Science',
    units: 0.7
  }]
}, {
  name: 'PSHS Grade 9',
  subjects: [{
    name: 'Biology',
    units: 1
  }, {
    name: 'Chemistry',
    units: 1
  }, {
    name: 'Physics',
    units: 1
  }, {
    name: 'Mathematics',
    units: 1
  }, {
    name: 'English',
    units: 1
  }, {
    name: 'Filipino',
    units: 1
  }, {
    name: 'Social Science',
    units: 1
  }, {
    name: 'PEHM',
    units: 1
  }, {
    name: 'Statistics',
    units: 1
  }, {
    name: 'Computer Science',
    units: 1
  }]
}, {
  name: 'PSHS Grade 10',
  subjects: [{
    name: 'Biology',
    units: 1
  }, {
    name: 'Chemistry',
    units: 1
  }, {
    name: 'Physics',
    units: 1
  }, {
    name: 'Mathematics',
    units: 1.3
  }, {
    name: 'English',
    units: 1
  }, {
    name: 'Filipino',
    units: 1
  }, {
    name: 'Social Science',
    units: 1
  }, {
    name: 'PEHM',
    units: 1
  }, {
    name: 'Research',
    units: 1
  }, {
    name: 'Computer Science',
    units: 1
  }, {
    name: 'Elective',
    units: 1
  }]
}, {
  name: 'PSHS Grade 10 (no elective)',
  subjects: [{
    name: 'Biology',
    units: 1
  }, {
    name: 'Chemistry',
    units: 1
  }, {
    name: 'Physics',
    units: 1
  }, {
    name: 'Mathematics',
    units: 1.3
  }, {
    name: 'English',
    units: 1
  }, {
    name: 'Filipino',
    units: 1
  }, {
    name: 'Social Science',
    units: 1
  }, {
    name: 'PEHM',
    units: 1
  }, {
    name: 'Research',
    units: 1
  }, {
    name: 'Computer Science',
    units: 1
  }]
}, {
  name: 'PSHS SYP',
  subjects: [{
    name: 'Research',
    units: 2
  }, {
    name: 'Core Science',
    units: 1.7
  }, {
    name: 'Elective',
    units: 1.7
  }, {
    name: 'Mathematics',
    units: 1
  }, {
    name: 'English',
    units: 1
  }, {
    name: 'Filipino',
    units: 1
  }, {
    name: 'Social Science',
    units: 1
  }]
}];
var setMap = new Map();

for (var _i = 0, _sets = sets; _i < _sets.length; _i++) {
  var set = _sets[_i];
  var name = set.name,
      subjects = set.subjects;
  setMap.set(name, subjects);
}

export var setNames = Array.from(setMap.keys());
export default setMap;