import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import localforage from 'localforage';
import Subjects, { setNames, compareSets } from './subjects';
Vue.use(Vuex);
var vuexLocal = new VuexPersist({
  key: 'gwa-calc',
  storage: localforage,
  asyncStorage: true
});

function getInitialState() {
  return {
    currentSet: 'PSHS Grade 7',
    customSets: {},
    grades: new Array(9).fill(1),
    transmuteGrades: new Array(2).fill(1),
    isDarkMode: false,
    isHiddenPshs: false
  };
}

export default new Vuex.Store({
  plugins: [vuexLocal.plugin],
  state: getInitialState(),
  mutations: {
    deleteSet: function deleteSet(state, key) {
      return Vue.delete(state.customSets, key);
    },
    popGrade: function popGrade(state) {
      return state.grades.pop();
    },
    reset: function reset(state) {
      var initialState = getInitialState();
      Object.keys(initialState).forEach(function (key) {
        Vue.set(state, key, initialState[key]);
      });
    },
    saveSet: function saveSet(state, _ref) {
      var name = _ref.name,
          subjects = _ref.subjects;
      Vue.set(state.customSets, name, subjects.map(function (subject) {
        return {
          name: subject.name,
          units: parseFloat(subject.units)
        };
      }));
    },
    updateCurrentSet: function updateCurrentSet(state, set) {
      return state.currentSet = set;
    },
    updateDarkMode: function updateDarkMode(state, mode) {
      return state.isDarkMode = mode;
    },
    updateGrade: function updateGrade(state, _ref2) {
      var id = _ref2.id,
          grade = _ref2.grade;
      return Vue.set(state.grades, id, grade);
    },
    updateHiddenPshs: function updateHiddenPshs(state, isHidden) {
      return state.isHiddenPshs = isHidden;
    },
    updateTransmuteGrade: function updateTransmuteGrade(state, _ref3) {
      var id = _ref3.id,
          grade = _ref3.grade;
      return Vue.set(state.transmuteGrades, id, grade);
    }
  },
  getters: {
    appVersion: function appVersion() {
      return process.env.BUILD_DATE;
    },
    currentSet: function currentSet(state) {
      return state.currentSet;
    },
    currentSetSubjects: function currentSetSubjects(state) {
      var set = state.currentSet;
      return Subjects.has(set) ? Subjects.get(set) : state.customSets[set];
    },
    allCustomSets: function allCustomSets(state) {
      return state.customSets;
    },
    customSet: function customSet(state) {
      return function (set) {
        if (state.customSets.hasOwnProperty(set)) {
          return {
            name: set,
            subjects: state.customSets[set]
          };
        } else {
          return {
            name: set,
            subjects: []
          };
        }
      };
    },
    customSets: function customSets(state) {
      return Array.from(Object.keys(state.customSets));
    },
    grades: function grades(state) {
      return state.grades;
    },
    transmuteGrades: function transmuteGrades(state) {
      return state.transmuteGrades;
    },
    isDarkMode: function isDarkMode(state) {
      return state.isDarkMode;
    },
    pshsSets: function pshsSets() {
      return setNames;
    }
  },
  actions: {
    clearAllData: function clearAllData(_ref4) {
      var commit = _ref4.commit;
      return commit('reset');
    },
    deleteSet: function deleteSet(_ref5, set) {
      var state = _ref5.state,
          commit = _ref5.commit,
          dispatch = _ref5.dispatch;

      if (state.currentSet === set) {
        dispatch('updateCurrentSet', 'PSHS Grade 7').then(function () {
          commit('deleteSet', set);
        });
      } else {
        commit('deleteSet', set);
      }
    },
    editSet: function editSet(_ref6, _ref7) {
      var state = _ref6.state,
          commit = _ref6.commit,
          dispatch = _ref6.dispatch;
      var oldName = _ref7.oldName,
          newName = _ref7.newName,
          subjects = _ref7.subjects;
      var oldSets = state.customSets;
      var newSets = {};
      var subjectsHaveChanged = false;
      Object.keys(oldSets).map(function (name) {
        if (name === oldName) {
          // Push new renamed/edited set
          newSets[newName] = subjects.map(function (subject) {
            return {
              name: subject.name,
              units: parseFloat(subject.units)
            };
          }); // Check if new subjects are different

          subjectsHaveChanged = compareSets(oldSets[oldName], newSets[newName]);
        } else {
          // Push old set
          newSets[name] = oldSets[name];
        }
      }); // Save new sets

      for (var _i = 0, _Object$keys = Object.keys(oldSets); _i < _Object$keys.length; _i++) {
        var set = _Object$keys[_i];
        Vue.delete(state.customSets, set);
      }

      for (var _i2 = 0, _Object$keys2 = Object.keys(newSets); _i2 < _Object$keys2.length; _i2++) {
        var _set = _Object$keys2[_i2];
        Vue.set(state.customSets, _set, newSets[_set]);
      } // Switch to new set if current set is the old one


      if (state.currentSet === oldName) {
        if (subjectsHaveChanged) {
          // New subjects, clear grades!
          dispatch('updateCurrentSet', newName);
        } else {
          // Same subjects, no need to clear grades
          commit('updateCurrentSet', newName);
        }
      }
    },
    saveSet: function saveSet(_ref8, _ref9) {
      var state = _ref8.state,
          commit = _ref8.commit,
          dispatch = _ref8.dispatch;
      var name = _ref9.name,
          subjects = _ref9.subjects;
      commit('saveSet', {
        name: name,
        subjects: subjects
      }); // Clear grades in case subjects were changed

      if (state.currentSet === name) {
        dispatch('clearGrades', name);
      }
    },
    decrement: function decrement(_ref10, _ref11) {
      var commit = _ref10.commit,
          state = _ref10.state;
      var id = _ref11.id,
          inTransmuteMode = _ref11.inTransmuteMode;
      var grade = (inTransmuteMode ? state.transmuteGrades : state.grades)[id]; // Decrease value (i.e. higher grade)

      if (grade > 1) {
        if (grade > 3) {
          grade -= 1;
        } else {
          grade -= 0.25;
        }

        commit(inTransmuteMode ? 'updateTransmuteGrade' : 'updateGrade', {
          id: id,
          grade: grade
        });
      }
    },
    increment: function increment(_ref12, _ref13) {
      var commit = _ref12.commit,
          state = _ref12.state;
      var id = _ref13.id,
          inTransmuteMode = _ref13.inTransmuteMode;
      var grade = (inTransmuteMode ? state.transmuteGrades : state.grades)[id]; // Increase value (i.e. lower grade)

      if (grade < 5) {
        if (grade > 2.75) {
          grade += 1;
        } else {
          grade += 0.25;
        }

        commit(inTransmuteMode ? 'updateTransmuteGrade' : 'updateGrade', {
          id: id,
          grade: grade
        });
      }
    },
    clearGrades: function clearGrades(_ref14, set) {
      var commit = _ref14.commit,
          state = _ref14.state;
      // Empty array first
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = state.grades[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var grade = _step.value;
          commit('popGrade');
        } // Then repopulate with ones

      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      var newSet = set || state.currentSet;
      var setLength = (Subjects.has(newSet) ? Subjects.get(newSet) : state.customSets[newSet]).length;

      for (var id = 0; id < setLength; id++) {
        commit('updateGrade', {
          id: id,
          grade: 1
        });
      }
    },
    updateCurrentSet: function updateCurrentSet(_ref15, set) {
      var commit = _ref15.commit,
          dispatch = _ref15.dispatch;
      dispatch('clearGrades', set).then(function () {
        commit('updateCurrentSet', set);
      });
    }
  }
});